<template>
  <div class="ktp-wrapper">
    <el-select
        @change="getTopicPlans(currentPage)"
        :placeholder="$t('profile.ktp.subject')"
        v-model="subject_id"
        clearable
        class="subject-select">
        <el-option v-for="item in subjects"
                   :key="item.id"
                   :label="item.name"
                   :value="item.id">
        </el-option>
    </el-select>
    <el-select
        @change="getTopicPlans(currentPage)"
        :placeholder="$t('profile.ktp.class')"
        v-model="class_id"
        clearable
        class="class-select">
        <el-option v-for="item in classes"
                   :key="item.id"
                   :label="item.name"
                   :value="item.id">
        </el-option>
    </el-select>
    <div class="ktp-table-block" v-loading="loadingService">
        <table>
        <thead>
        <tr>
            <td>{{ $t('profile.ktp-page.lesson') }}</td>
            <td>{{ $t('profile.ktp-page.topic') }}</td>
            <td>{{ $t('profile.ktp-page.theme') }}</td>
            <td>{{ $t('profile.ktp-page.hours') }}</td>
            <td></td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="topicPlan in topicPlans">
            <td>{{ topicPlan.name }}</td>
            <td>{{ topicPlan.calendar.name }}</td>
            <td>{{ topicPlan.topic.name }}</td>
            <td>1</td>
            <td>
            <div @click="$router.push({ name: 'ktp-details', params: { id: topicPlan.id } })" class="btn-watch">{{ $t('profile.ktp.view') }}</div>
            </td>
        </tr>
        </tbody>
        </table>
    </div>

    <b-pagination
        v-if="total > perPage"
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        last-number
        first-number
        @change="getTopicPlans"
        hide-goto-end-buttons
        align="center"
        next-text=">"
        prev-text="<">
    </b-pagination>
  </div>
</template>
<script>
export default {
    data() {
        return {
            loadingTimeout: false,
            loadingService: false,
            topicPlans: [],
            subjects: [],
            subject_id: '',
            classes: [],
            class_id: '',
            total: 0,
            perPage: 0,
            currentPage: 1
        }
    },
    mounted() {
        this.getTopicPlans(this.currentPage)
    },
    methods: {
        getTopicPlans(page) {
            this.loadingService = true;
            this.loadingTimeout = setTimeout(() => {
                this.loadingService = false;
            }, 5000);
            let subjectId = this.subject_id
            let classId = this.class_id
            this.$http.get(`${window.API_ROOT}/api/teacher/calendar-topic-plans?page=${page}&subject_id=${subjectId}&class_id=${classId}`)
                .then((res) => {
                    this.topicPlans = res.body.items.data
                    this.subjects = res.body.subjects
                    this.classes = res.body.classes
                    this.total = res.body.items.total
                    this.perPage = res.body.items.per_page
                    this.loadingService = false
                    window.clearTimeout(this.loadingTimeout)
                })
                .catch(() => {
                    this.loadingService = false
                    window.clearTimeout(this.loadingTimeout)
                })
        }
    }
}
</script>

<style lang="less" scoped>
.footer-wave{
  margin-top: 2rem;
  height: 90px;
  width:100%;
  background-image: url("/images/footer-wave-ktp.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.or-ktp{
  width:max-content;
  margin: 15px auto 20px;
}
.btn-download {
  width: 146px;
  margin: 0 auto;
  user-select: none;
  background: #473F95;
  border-radius: 5px;
  cursor:pointer;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
}
.file-download-img{
  background-image: url("/images/file-download.svg");
  margin: 12px auto 0;
  width: 19px;
  height: 31px;
  background-size: contain;
}
.file-download{
  user-select: none;
  cursor: pointer;
  margin: 0 auto;
  padding:5px;
  text-overflow: ellipsis;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  width:120px;
  height:75px;
  border: 1px dashed #473F95;
}
.ktp-file-download-wrapper{
  margin-top: 1rem;
  display:flex
}
.ktp-info-section-inside{
  margin-top: 5px;
  background: #EEEAFB;
  border-radius: 4px;
  padding:15px

}
.ktp-info-section{
  margin-top: 32px;
  b{font-weight: bold}
}
.ktp-info-body{
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;

  color: #473F95;
  padding: 0 38px;
}
.btn-back-ktp{
  cursor: pointer;
  user-select: none;
  height:58px;
  width:58px;
  background: #EEEAFB;
  border-radius: 5px 0;
  display:flex;
  justify-content:center;
}
.btn-back-arrow{
  margin: auto;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/images/arrow-left.svg");
}
.subject-title{
  font-size: 24px;
  line-height: 28px;
}
.ktp-info-header{
  font-size: 20px;
  line-height: 23px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  font-style: normal;
  text-align: center;
  color: #473F95;
  margin:0 auto;
  width:max-content;
}
.ktp-info-wrapper{
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
.btn-watch {
    user-select: none;
    background: #473F95;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    padding: 14px 38px;
}

table {
  width: 100%;
}

thead {
  background-color: white;
  color: #D23168;
  font-family: 'Open Sans', 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
    td:nth-child(1) {
        min-width: 65px;
    }
    td:nth-child(2) {
        min-width: 330px;
    }
    td:nth-child(3) {
        min-width: 247px;
    }
    td:nth-child(4) {
        min-width: 115px;
        white-space: nowrap;
    }
    td:nth-child(5) {
        min-width: 198px;
    }
  td {
    padding: 22px 20px 10px;
  }

}

tbody {
  background-color: white;
  color: #473F95;
    td:nth-child(1) {
        min-width: 65px;
    }
    td:nth-child(2) {
        min-width: 330px;
    }
    td:nth-child(3) {
        min-width: 247px;
    }
    td:nth-child(4) {
        min-width: 115px;
    }
    td:nth-child(5) {
        min-width: 198px;
    }
  td {
    padding: 16px 20px 10px;
  }
}

.ktp-wrapper {
    // margin: 0 auto;
    max-width: 100%;
}

.subject-select {
  width: 260px;
  height: 36px;
  margin-right: 10px;
}

.class-select {
  width: 154px;
  height: 36px;
}
.pagination {
    border: none;
    box-shadow: none;
    margin-bottom: 30px;
    padding-top: 50px;
}
/deep/ .pagination .page-item .page-link {
    background: #EEEAFB;
    color: #473F95;
    border-radius: 5px;
    height: 45px;
    min-width: 58px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    border: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/deep/ .pagination .page-item[role=separator] .page-link {
    background: transparent;
    font-size: 28px;
    display: flex;
    align-items: flex-start;
}
/deep/ .pagination .page-item.active .page-link {
    background: #473F95;
    color: #fff;
}
/deep/ .pagination .page-item.active .page-link:focus {
    box-shadow: none;
}
/deep/ .pagination .page-item:last-child .page-link:hover,
/deep/ .pagination .page-item:first-child .page-link:hover {
    background: #EEEAFB;
    color: #473F95;
}
/deep/ .page-link:focus {
    box-shadow: none;
}
.ktp-table-block {
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    overflow-x: auto;
    margin-top: 3rem;
}
@media (max-width: 767px) {
    .subject-select {
        width: 100%;
        margin-bottom: 18px;
    }
    .class-select {
        width: 100%;
    }
    .ktp-table-block {
        margin-top: 12px;
    }
}
</style>